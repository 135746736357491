import MomentUtils from "@date-io/moment";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { DATE_FORMAT3, dateTimeView, handleErrorMessage } from "../../../utils/Utils";
import { localization, tableIcons } from "../../system/MaterialTableUtils";
import useEffectOnce from "../../system/useEffectOnce";

const feeStatus = [
  {
    id: "CHARGED",
    name: "Naplaćena",
  },
  {
    id: "WAITING_ON_DELIVERY",
    name: "Čeka na predaju",
  },
  {
    id: "COMPLETED",
    name: "Predata",
  },
];

export default function MemberFees() {
  const { token, showMessage } = useAuth();
  const { id } = useParams();
  const [fees, setFees] = useState([]);
  const [fee, setFee] = useState();
  const [teams, setTeams] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  localization.body.emptyDataSourceMessage = loading ? "Učitavanje podataka" : "Nema podataka";

  const fetchData = () => {
    axios
      .get(Endpoints.FEE + "/byMember/" + id, headers(token))
      .then((result) => {
        const { memberFees, memberTeams } = result.data;
        let parseFees = memberFees?.map((f) => {
          return {
            id: f.id,
            month: f.month,
            year: f.year,
            amount: f.amount,
            feeDate: f.feeDate,
            groupName: f.team ? f.team.name : "",
            statusName: feeStatus.find((fee) => fee.id === f.feeStatus)?.name,
            team: f.team,
          };
        });
        if (parseFees) {
          setFees(parseFees);
        }

        if (memberTeams) {
          setTeams(memberTeams?.map((mt) => mt.team));
        }
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      })
      .finally(() => setLoading(false));
  };

  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    { title: "Mesec ", field: "month" },
    { title: "Godina", field: "year" },
    { title: "Cena", field: "amount" },
    {
      title: "Datum uplate",
      field: "feeDate",
      type: "date",
      render: (rowData) => dateTimeView(rowData.feeDate).format("DD.MM.YYYY."),
    },
    { title: "Grupa", field: "groupName" },
    { title: "Status", field: "statusName" },
  ];

  const handleDelete = (newData, resolve, reject) => {
    axios
      .delete(Endpoints.FEE + "/" + newData.id, headers(token))
      .then((response) => {
        resolve();
        fetchData();
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
        reject();
      });
  };

  const handleChange = (e) => setFee({ ...fee, [e.target.name]: e.target.value });

  const editNewFee = (item) => {
    setFee(
      item
        ? {
            ...item,
            status: feeStatus.find((fee) => fee.name === item.statusName),
          }
        : {}
    );
    setOpenDialog(true);
  };

  const saveFee = () => {
    const newData = {
      ...fee,
      memberId: id,
      paymentDate: moment(fee.feeDate).format(DATE_FORMAT3),
      teamId: fee.team.id,
      feeStatus: fee.status.id,
    };

    axios
      .post(`${Endpoints.FEE}/admin/save`, newData, headers(token))
      .then((response) => {
        fetchData();
        setOpenDialog(false);
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  return (
    <React.Fragment>
      <MaterialTable
        icons={tableIcons}
        title={""}
        columns={columns}
        data={fees}
        localization={localization}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        editable={{
          onRowDelete: (newData) =>
            new Promise((resolve, reject) => {
              handleDelete(newData, resolve, reject);
            }),
        }}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: "Izmeni",
            onClick: (event, rowData) => {
              editNewFee(rowData);
            },
          },
          {
            icon: () => <AddBox />,
            tooltip: "Dodaj",
            isFreeAction: true,
            onClick: () => editNewFee(),
          },
        ]}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>{fee?.id ? "Izmeni članarinu" : "Nova članarina"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField variant="outlined" margin="normal" id="month" name="month" label="Mesec" value={fee?.month} onChange={handleChange} required fullWidth />
            </Grid>
            <Grid item sm={6}>
              <TextField variant="outlined" margin="normal" id="year" name="year" label="Godina" value={fee?.year} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item sm={6}>
              <TextField variant="outlined" margin="normal" id="amount" name="amount" label="Cena" value={fee?.amount} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item sm={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  views={["date"]}
                  ampm={false}
                  margin="normal"
                  id="feeDate"
                  name="feeDate"
                  label="Datum uplate"
                  value={fee?.feeDate ?? null}
                  autoOk
                  format="DD.MM.YYYY."
                  onChange={(value) => {
                    setFee({ ...fee, feeDate: value._d });
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                options={feeStatus}
                getOptionLabel={(option) => option.name}
                value={fee?.status}
                onChange={(event, value) => {
                  setFee({ ...fee, status: value });
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Status članarine" required fullWidth />}
              />
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                options={teams}
                getOptionLabel={(option) => option.name}
                value={fee?.team}
                onChange={(event, value) => {
                  setFee({ ...fee, team: value });
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Grupa" required fullWidth />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDialog(false)}>
            Odustani
          </Button>
          <Button variant="contained" color="primary" onClick={saveFee}>
            Snimi
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
